import React, { useContext } from "react";
import styled from "@emotion/styled";
import { Link } from "gatsby";
// utils
import { Color } from "../../utils/color";
import { NavLists } from "../../utils/navlists";
import { NavToggleContext } from "../providers/navToggleProvider";
// head === true を抽出
const NavItems = NavLists.filter((Item) => {
  return Item.head === true;
});

export const HeaderNav = () => {
  const { isOpen, setIsOpen } = useContext(NavToggleContext);
  const onClickCloseNav = () => setIsOpen(false);
  return (
    <SNav data-nav-state={isOpen}>
      {NavItems.map(({ jpTxt, enTxt, url }) => {
        return (
          <Link to={url} key={url} onClick={onClickCloseNav}>
            <span>{jpTxt}</span>
            <span>{enTxt}</span>
          </Link>
        );
      })}
    </SNav>
  );
};

const SNav = styled.nav`
  grid-area: nav;
  height: 64px;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(min(160px, 100%), 1fr));
  gap: 0px;
  text-align: center;
  a {
    display: inline-flex;
    flex-direction: column;
    justify-content: center;
    border: 1px solid ${Color.gray100};
    border-bottom: none;
    border-left: none;
    transition: all 0.2s;
    @media screen and (min-width: 1024px) {
      &:hover {
        background-color: ${Color.gray100};
      }
    }
    @media screen and (max-width: 1023px) {
      &:active {
        background-color: ${Color.gray100};
      }
    }
    span {
      color: ${Color.gray400};
      line-height: 1;
      &:nth-of-type(1) {
        font-size: 0.8rem;
      }
      &:nth-of-type(2) {
        font-size: 0.6rem;
        margin-top: 8px;
      }
    }
  }

  /* ---------- */
  /* tb */
  @media screen and (max-width: 1023px) {
    position: fixed;
    top: 64px;
    width: 100%;
    height: 100vh;
    background-color: ${Color.white};
    display: flex;
    flex-direction: column;
    transition: all 0.2s;
    &[data-nav-state="true"] {
      left: 0;
    }
    &[data-nav-state="false"] {
      left: 100%;
    }
    a {
      padding: 2rem 0;
      border: 1px solid ${Color.gray100};
      border-bottom: none;
      border-left: none;
      border-right: none;
      &:last-of-type {
        border-bottom: 1px solid ${Color.gray100};
      }
    }
  }
`;
