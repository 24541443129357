import React from "react";
import styled from "@emotion/styled";
import { useState } from "react";
// utils
import { Color } from "../../utils/color";

export const TopBar = () => {
  const [isShow, setIsShow] = useState(true);
  const closeBar = () => {
    setIsShow(false);
  };
  return (
    <SBar data-bar-show={isShow}>
      <span></span>
      <p>新型コロナウイルスに関するXXXXXXXXお知らせ</p>
      <button onClick={closeBar} className="material-icons-outlined">
        close
      </button>
    </SBar>
  );
};

const SBar = styled.div`
  grid-area: topbar;
  width: 100%;
  padding: 0 2rem;
  transition: all 0.2s;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: ${Color.red};
  overflow: hidden;
  span {
    line-height: 1;
  }
  p {
    text-align: center;
    font-size: 0.8rem;
    line-height: 1;
    color: ${Color.white};
    line-height: 1;
  }
  button {
    background-color: ${Color.white};
    border: none;
    border-radius: 100vh;
    padding: 0.1rem;
    font-size: 0.8rem;
    line-height: 1;
    cursor: pointer;
  }
  &[data-bar-show="true"] {
    height: 32px;
  }
  &[data-bar-show="false"] {
    height: 0;
    span,
    p,
    button {
      display: none;
    }
  }
  /* ---------- */
  /* tb */
  @media screen and (max-width: 1023px) {
    height: 0;
    display: none;
    span,
    p,
    button {
      display: none;
    }
  }
`;
