import React from "react";
import styled from "@emotion/styled";
import "../../styles/global.css";
// components
import { Seo } from "../seo/seo";
import { TopbarVisibleProvider } from "../providers/topbarVisibleProvider";
import { NavToggleProvider } from "../providers/navToggleProvider";
import { TopBar } from "../bar/topBar";
import { Header } from "../header/header";
import { Footer } from "../footer/footer";

export const DefaultLayout = (props) => {
  return (
    <>
      <TopbarVisibleProvider>
        <Seo pageTitle={props.pageTitle} />
        <TopBar />
        <NavToggleProvider>
          <Header />
        </NavToggleProvider>
        <SMain>{props.children}</SMain>
        <Footer />
      </TopbarVisibleProvider>
    </>
  );
};

const SMain = styled.main`
  grid-area: main;
  animation-name: pageMove;
  animation-fill-mode: forwards;
  animation-duration: 0.5s;
  animation-timing-function: ease-in-out;
  @keyframes pageMove {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }
`;
