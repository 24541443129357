import React from "react";
import { createContext, useState } from "react";

export const TopbarVisibleContext = createContext({});

export const TopbarVisibleProvider = (props) => {
  const { children } = props;
  const [isVisible, setIsVisible] = useState(true);

  return (
    <TopbarVisibleContext.Provider value={{ isVisible, setIsVisible }}>
      {children}
    </TopbarVisibleContext.Provider>
  );
};
