import React from "react";
import styled from "@emotion/styled";
import { Link } from "gatsby";
// utils
import { Color } from "../../utils/color";
import { NavLists } from "../../utils/navlists";
// foot === true を抽出
const NavItems = NavLists.filter((Item) => {
  return Item.foot === true;
});

export const FooterNav = () => {
  return (
    <SNav>
      {NavItems.map(({ jpTxt, url }) => {
        return (
          <Link to={url} key={url}>
            {jpTxt}
          </Link>
        );
      })}
    </SNav>
  );
};

const SNav = styled.nav`
  grid-area: fnav;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(min(240px, 100%), 1fr));
  gap: 8px;
  a {
    display: inline-flex;
    align-items: center;
    color: ${Color.white};
    font-size: 0.9rem;
    transition: all 0.2s;
    @media screen and (min-width: 1024px) {
      &:hover {
        color: ${Color.blue};
      }
    }
    @media screen and (max-width: 1023px) {
      &:active {
        color: ${Color.blue};
      }
    }
  }
`;
