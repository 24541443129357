import React from "react";
import { createContext, useState } from "react";

export const NavToggleContext = createContext({});

export const NavToggleProvider = (props) => {
  const { children } = props;
  const [isOpen, setIsOpen] = useState(false);

  return (
    <NavToggleContext.Provider value={{ isOpen, setIsOpen }}>
      {children}
    </NavToggleContext.Provider>
  );
};
