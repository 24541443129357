import React from "react";
import styled from "@emotion/styled";
import { Link } from "gatsby";
// utils
import { Color } from "../../utils/color";

export const HeaderLogo = () => {
  return (
    <SLogo>
      <Link to="/">TOFU</Link>
    </SLogo>
  );
};

const SLogo = styled.div`
  grid-area: logo;
  display: inline-flex;
  width: 160px;
  border: 1px solid ${Color.gray100};
  border-bottom: none;
  a {
    display: inline-flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    font-size: 1.2rem;
    line-height: 1;
    font-weight: 700;
    color: ${Color.gray400};
    @media screen and (min-width: 1024px) {
      &:hover {
        background-color: ${Color.gray100};
      }
    }
    @media screen and (max-width: 1023px) {
      &:active {
        background-color: ${Color.gray100};
      }
    }
  }

  /* ---------- */
  /* tb */
  @media screen and (max-width: 1023px) {
    width: auto;
    border-right: none;
    a {
      padding: 0 1rem;
    }
  }
`;
