export const NavLists = [
  {
    jpTxt: "ホーム",
    enTxt: "Home",
    url: "/",
    head: true,
    foot: true,
  },
  {
    jpTxt: "TOFUについて",
    enTxt: "About",
    url: "/about",
    head: true,
    foot: true,
  },
  { jpTxt: "製品一覧", enTxt: "Items", url: "/items", head: true, foot: true },
  {
    jpTxt: "ブランドから探す",
    enTxt: "Serch by Brand",
    url: "/brand",
    head: true,
    foot: true,
  },
  {
    jpTxt: "ジャンルから探す",
    enTxt: "Serch By Genre",
    url: "/genre",
    head: true,
    foot: true,
  },
  { jpTxt: "セール製品", enTxt: "Sale", url: "/sale", head: false, foot: true },
  {
    jpTxt: "ピックアップ製品",
    enTxt: "PickUp",
    url: "/pickup",
    head: false,
    foot: true,
  },
  { jpTxt: "お知らせ", enTxt: "News", url: "/news", head: false, foot: true },
];
