import React from "react";
import Helmet from "react-helmet";
import Icon from "../../images/favicon.ico";

export const Seo = (props) => {
  return (
    <Helmet>
      <html lang="ja" />
      <title>{props.pageTitle} | TOFU</title>
      <link rel="icon" type="image/png" href={Icon} />
    </Helmet>
  );
};
