import React from "react";
import styled from "@emotion/styled";
import { useContext } from "react";
// components
import { NavToggleContext } from "../providers/navToggleProvider";
// utils
import { Color } from "../../utils/color";

export const HamburgerIcon = () => {
  const { isOpen, setIsOpen } = useContext(NavToggleContext);
  const onClickHamburger = () => setIsOpen(!isOpen);

  return (
    <SIcon onClick={onClickHamburger} data-nav-state={isOpen}>
      <div></div>
      <div></div>
      <div></div>
    </SIcon>
  );
};

const SIcon = styled.button`
  grid-area: icon;
  width: 64px;
  height: 64px;
  background-color: none;
  border: none;
  display: inline-flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: ${Color.gray100};
  div {
    width: 24px;
    height: 1px;
    background-color: ${Color.gray400};
    transition: all 0.2s;
  }
  &[data-nav-state="true"] {
    div {
      &:nth-of-type(1) {
        transform: translateY(1px) rotate(-45deg);
      }
      &:nth-of-type(2) {
        opacity: 0;
      }
      &:nth-of-type(3) {
        transform: translateY(-1px) rotate(45deg);
      }
    }
  }
  &[data-nav-state="false"] {
    div {
      &:nth-of-type(1) {
        transform: translateY(-4px);
      }
      &:nth-of-type(2) {
      }
      &:nth-of-type(3) {
        transform: translateY(4px);
      }
    }
  }
  /* ---------- */
  /* tb */
  @media screen and (min-width: 1024px) {
    display: none;
  }
`;
