export const Color = {
  primary: "#F29544",
  secondary: "#4EBFD9",
  white: "#fff",
  gray100: "#e5e7eb",
  gray200: "#A6A6A6",
  gray300: "#595959",
  gray400: "#404040",
  black: "#0d0d0d",
  blue: "#3498db",
  green: "#2ecc71",
  red: "#e74c3c",
  yellow: "#f1c40f",
};
