import React from "react";
import styled from "@emotion/styled";
// components
import { FooterLogo } from "./footerLogo";
import { FooterInfo } from "./footerInfo";
import { FooterNav } from "./footerNav";
// utils
import { Color } from "../../utils/color";

export const Footer = () => {
  const onClickScrollTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };
  return (
    <SFooter>
      <SBtn onClick={onClickScrollTop}>トップに戻る</SBtn>
      <SContainer>
        <FooterLogo />
        <FooterInfo />
        <FooterNav />
      </SContainer>
      <SFooting>
        <p>このサイトはデモサイトです。</p>
        <p>Demand Link Co.,Ltd.</p>
      </SFooting>
    </SFooter>
  );
};

const SFooter = styled.footer`
  grid-area: footer;
  background-color: ${Color.gray400};
`;

const SContainer = styled.div`
  padding: 4rem 0;
  width: 96%;
  max-width: 1366px;
  margin: auto;
  display: grid;
  grid-template-rows: auto auto;
  grid-template-columns: 240px 1fr;
  grid-template-areas:
    "flogo fnav"
    "flogo finfo";
  /* ---------- */
  /* tb */
  @media screen and (max-width: 1023px) {
    padding: 2rem 0;
    grid-template-rows: auto auto auto;
    grid-template-columns: 1fr;
    grid-template-areas:
      "fnav"
      "finfo"
      "flogo";
  }
`;

const SBtn = styled.button`
  width: 100%;
  height: 64px;
  background-color: ${Color.gray300};
  border: none;
  display: flex;
  align-items: center;
  justify-content: center;
  color: ${Color.white};
  cursor: pointer;
  /* ---------- */
  /* tb */
  @media screen and (max-width: 1023px) {
    height: 48px;
  }
`;

const SFooting = styled.div`
  background-color: ${Color.gray400};
  padding-bottom: 2rem;
  p {
    color: ${Color.gray200};
    text-align: center;
    font-size: 0.9rem;
  }
`;
