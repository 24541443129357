import React from "react";
import styled from "@emotion/styled";
// components
import { HeaderLogo } from "./headerLogo";
import { HeaderNav } from "./headerNav";
import { HeaderInfo } from "./headerInfo";
import { HamburgerIcon } from "./hamburgerIcon";
// utils
import { Color } from "../../utils/color";

export const Header = () => {
  return (
    <SHeader>
      <HeaderLogo />
      <HeaderInfo />
      <HeaderNav />
      <HamburgerIcon />
    </SHeader>
  );
};

const SHeader = styled.header`
  grid-area: header;
  width: 100%;
  position: sticky;
  top: 0;
  display: grid;
  grid-template-rows: auto auto;
  grid-template-columns: auto 1fr auto;
  grid-template-areas:
    "logo info"
    "logo nav";
  background-color: ${Color.white};
  z-index: 1000;
  border-bottom: 1px solid ${Color.gray100};
  /* ---------- */
  /* tb */
  @media screen and (max-width: 1023px) {
    grid-template-areas: "logo info icon";
  }
`;
