import React from "react";
import styled from "@emotion/styled";
import { Link } from "gatsby";
// utils
import { Color } from "../../utils/color";

const NavItems = [
  { id: "01", txt: "特定商取引法に基づく表記", url: "/" },
  { id: "02", txt: "プライバシーポリシー", url: "/" },
  { id: "03", txt: "利用規約", url: "/" },
];

export const FooterInfo = () => {
  return (
    <SNav>
      {NavItems.map(({ id, txt, url }) => {
        return (
          <Link to={url} key={id}>
            {txt}
          </Link>
        );
      })}
    </SNav>
  );
};

const SNav = styled.nav`
  grid-area: finfo;
  display: flex;
  justify-content: left;
  margin-top: 2rem;
  padding-top: 2rem;
  border-top: 1px solid ${Color.gray300};
  a {
    display: inline-flex;
    align-items: center;
    color: ${Color.gray200};
    font-size: 0.9rem;
    margin-right: 2rem;
    transition: all 0.2;
    &:last-of-type {
      margin-right: 0;
    }
    @media screen and (min-width: 1024px) {
      &:hover {
        color: ${Color.blue};
      }
    }
    @media screen and (max-width: 1023px) {
      &:active {
        color: ${Color.blue};
      }
    }
  }
  /* ---------- */
  /* tb */
  @media screen and (max-width: 1023px) {
    flex-direction: column;
    a {
      margin-right: 0;
      margin-top: 0.5rem;
      &:first-of-type {
        margin-top: 0;
      }
    }
  }
`;
