import React from "react";
import styled from "@emotion/styled";
import { Link } from "gatsby";
// utils
import { Color } from "../../utils/color";

const NavItems = [
  { txt: "マイページ", icon: "person", href: "/mypage" },
  { txt: "カート", icon: "shopping_cart", href: "/mypage/cart" },
];

export const HeaderInfo = () => {
  return (
    <SInfo>
      {NavItems.map(({ txt, icon, href }) => {
        return (
          <Link to={href} key={txt}>
            <span className="material-icons-outlined">{icon}</span>
            <span>{txt}</span>
          </Link>
        );
      })}
    </SInfo>
  );
};

const SInfo = styled.nav`
  grid-area: info;
  margin-left: auto;
  a {
    width: 160px;
    height: 64px;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    border: 1px solid ${Color.gray100};
    border-bottom: none;
    border-left: none;

    transition: all 0.2s;
    @media screen and (min-width: 1024px) {
      &:hover {
        background-color: ${Color.gray100};
      }
    }
    @media screen and (max-width: 1023px) {
      &:active {
        background-color: ${Color.gray100};
      }
    }
    &:nth-of-type(1) {
      border-left: 1px solid ${Color.gray100};
    }
    &:nth-of-type(2) {
    }

    span {
      color: ${Color.gray400};
      line-height: 1;
      &:nth-of-type(1) {
      }
      &:nth-of-type(2) {
        margin-left: 4px;
        font-size: 0.8rem;
      }
    }
  }

  /* ---------- */
  /* tb */
  @media screen and (max-width: 1023px) {
    margin-left: auto;
    a {
      width: 64px;
      border: 1px solid ${Color.gray100};
      border-bottom: none;
      border-left: none;
      &:nth-of-type(1) {
        border-left: 1px solid ${Color.gray100};
      }
      &:nth-of-type(2) {
      }

      span {
        &:nth-of-type(1) {
        }
        &:nth-of-type(2) {
          display: none;
          margin-left: 0;
        }
      }
    }
  }
`;
