import React from "react";
import styled from "@emotion/styled";
import { Link } from "gatsby";
// utils
import { Color } from "../../utils/color";

export const FooterLogo = () => {
  return (
    <SLogo>
      <Link to="/">
        <span>TOFU</span>
        <span>by DemandLink</span>
      </Link>
    </SLogo>
  );
};

const SLogo = styled.div`
  grid-area: flogo;
  display: inline-flex;
  align-items: center;
  a {
    display: flex;
    flex-direction: column;
    span {
      &:first-of-type {
        font-size: 1.2rem;
        font-weight: 700;
        color: ${Color.white};
      }
      &:last-of-type {
        font-size: 0.8rem;
        font-weight: 100;
        color: ${Color.gray200};
      }
    }
  }
  /* ---------- */
  /* tb */
  @media screen and (max-width: 1023px) {
    margin-top: 2rem;
    justify-content: center;
    a {
      align-items: center;
    }
  }
`;
